<template>
  <DropLoading v-if="loading" />
  <DSingleView v-else :color="drop.customization.primaryColor" :title="drop.name" :items="subNavigation">
    <template #title-append>
      <div class="flex-shrink-0 group block">
        <div class="flex items-center flex-row-reverse">
          <div class="h-9 w-9 rounded-full overflow-hidden bg-gray-100 flex items-center justify-center">
            <span class="text-2xl">💎</span>
          </div>
          <div class="mr-3 text-right">
            <p class="text-xs font-medium text-gray-500">
              Created by
            </p>
            <p class="text-sm font-medium text-gray-700">
              Diamond Hands Hotel
            </p>
          </div>
        </div>
      </div>
    </template>
  </DSingleView>
</template>

<script setup>
import DSingleView from '@/components/ui/layout/DSingleView.vue'

import { provide, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useQuery, useResult } from '@vue/apollo-composable'
import { InformationCircleIcon, PhotographIcon } from '@heroicons/vue/solid'
import { dropBySlug } from '@/gql/drops'
import { DSidebarLayout, useBreakpoint } from '@ui'
import DropLoading from '@/components/custom/DropLoading.vue'

const route = useRoute()
const slug = computed(() => route.params.slug)

const { result, loading } = useQuery(dropBySlug, { slug: slug.value })
const drop = useResult(result, null, data => data.drops[0])

const subNavigation = [{
  name: 'About',
  to: { name: 'ExploreDrop' },
  exact: true,
  icon: InformationCircleIcon,
}, {
  name: 'Gallery',
  to: { name: 'DropGallery' },
  icon: PhotographIcon,
}]

provide('drop', drop)
</script>