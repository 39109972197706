<template>
  <!-- Page title & actions -->
  <div :class="['border-b border-gray-200 px-4 pt-5 pb-3 bg-white bg-opacity-75 backdrop-filter backdrop-blur z-10 sm:flex sm:items-start sm:justify-between sm:px-6 lg:px-8 lg:sticky lg:top-0']">
    <div :class="`bg-gradient-to-r from-${color}-400 via-${color}-600 to-${color}-300 absolute top-0 left-0 w-full h-2`"></div>
    <div class="flex-1 min-w-0 space-y-1">
      <CollapseTransition>
        <slot name="title">
          <nav :class="[items ? 'pb-2' : '', 'flex']" aria-label="Breadcrumb" v-show="!collapseNavbar || !items">
            <ol role="list" class="flex items-center space-x-4">
              <li v-for="(item, i) in namedRoutes" :key="item.path">
                <div class="flex items-center">
                  <ChevronRightIcon v-if="i > 0" class="flex-shrink-0 h-5 w-5 text-gray-400 mr-4" aria-hidden="true" />
                  <AppLink :to="item.path" :class="[i === namedRoutes.length - 1 ? 'text-gray-900' : 'text-gray-600', 'text-xl font-bold sm:text-2xl sm:truncate']">
                    <template v-if="i === namedRoutes.length - 1">{{ title || item.meta.displayName }}</template>
                    <template v-else>{{ item.meta.displayName }}</template>
                  </AppLink>
                </div>
              </li>
            </ol>
          </nav>
        </slot>
      </CollapseTransition>
      <div class="flex align-center justify-between">
        <div>
          <div v-if="items">
            <nav class="flex space-x-4" aria-label="Tabs">
              <AppLink v-for="item in items" :key="item.name" :exact="item.exact" :to="item.to" class="px-3 py-2 font-medium text-sm rounded-md flex align-center" :active-class="`bg-${color}-100 bg-opacity-50 text-${color}-700`" inactive-class="text-gray-500 hover:text-gray-700" v-slot="{ active }">
                <component :is="item.icon" :class="[active ? `text-${color}-500` : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
                {{ item.name }}
              </AppLink>
            </nav>
          </div>
        </div>
        <div class="flex">
          <slot name="title-append"></slot>
        </div>
      </div>
    </div>
    <!-- <div class="hidden sm:block mt-4 flex sm:mt-0 sm:ml-4">
      <slot name="title-append"></slot>
    </div> -->
  </div>

  <div class="overflow-hidden flex-1 z-0 flex">
    <div class="flex-1 relative z-0 overflow-y-auto focus:outline-none">
      <slot>
        <router-view />
      </slot>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ChevronRightIcon } from '@heroicons/vue/solid'
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'
import AppLink from '@/components/roof/AppLink.vue'

const route = useRoute()
const router = useRouter()
const props = defineProps({
  color: String,
  title: String,
  sticky: Boolean,
  items: Array,
})

const namedRoutes = computed(() => route.matched.filter(({ meta }, idx) => 
  meta.displayName ||
  (idx === route.matched.length - 1 && props.title)
))

const collapseNavbar = inject('collapseNavbar')

const redirect = (e) => {
  router.push({ name: event.target.value })
}
</script>